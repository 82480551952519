@import "Colors.css";


.Personal {
  display: flex;
  flex-flow: column wrap;
}

.book {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.bookurl {
  text-decoration: none;
  display: flex;
  color: var(--color-6);
}

.bookurl:hover {
  color: var(--color-3);
}

.title {
  padding-right: 1vw;
}

.author {
  color: var(--color-4);
}
