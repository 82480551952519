.media {
  display: flex;
  margin-left: calc(8px + 1.5vw);
  margin-right: calc(8px + 1.5vw);
  flex-flow: column wrap;
}


.iconSection {
  display: flex;
  flex-flow: row wrap;
  padding-top: .2vh;
  padding-bottom: .2vh;
  text-decoration: none;
  color: var(--color-6);
  align-items: center;
}

.iconSection:hover {
  color: var(--color-4);
}

.iconlabel {
  display: flex;
  font-size: calc(6px + .5vh + .5vw);
  align-self: center;
  align-items: center;
}

