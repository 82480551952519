:root {
  --color-1: #e0e2dbff;
  --color-2: #d2d4c8ff;
  --color-3: #b8bdb5ff;
  --color-4: #889696ff;
  --color-5: #5f7470ff;
  --color-6: #555962;
  --color-7: #282c34;
}

h4 {
  display: flex;
  padding: 0;
  padding-bottom: 3vh;
  margin: 0;
  font-size: calc(10px + .7vh + .7vw);
  justify-content: center;
  color: var(--color-6);
}

.paragraph {
  margin: 0;
  padding-top: 3vh;
  padding-bottom: 3vh;
  color: var(--color-7);
}

p {
  margin: 0;
  padding: 0;
}

li {
  color: var(--color-5);
}

.content {
  padding-right: calc(6px + .7vw);
  font-size: calc(10px + .3vh + .4vw);
}

.iconBoxWrapper {
  display: flex;
}

.iconBox {
  width: 18px;
  margin-right: .4vw;
  display:inline-block;
  text-align: center;
  padding-top: calc(.1px + 0.03vh + 0.07vw);
}

.iconinside {
  text-align: center;
  display:inline-block;
  align-items: center;
  align-self: center;
}

