@import "Colors.css";

.Home {
  color: var(--color-7);
}

li{
  margin: 1vh 0;
}

.about {
  color: var(--color-7);
}

a {
  text-decoration: none;
  color: var(--color-4);
}

a:hover{
  color: var(--color-2);
}
