@import "Colors.css";

.Work {
  display: flex;
  flex-flow: column wrap;
}

.exp {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 3vh;
  justify-content: flex-start;
}



.workurl {
  text-decoration: none;
  color: var(--color-7)
}

.workurl:hover {
  color: var(--color-2);
}

.role {
  color: var(--color-5);
}
.time {
  color: var(--color-4);
}


