@import "Colors.css";

.App {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.text-top {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  height: calc(60px + 5vh + 5vw);
  width: calc(60px + 5vh + 5vw);
  margin-left: calc(8px + 1.5vw);
  margin-right: calc(8px + 1.5vw);
}

.image {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.nameheader {
  display: flex;
  flex-flow: column wrap;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  justify-content: space-evenly;
  align-items: center;
}

h1 {
  display: flex;
  margin: 0px;
  padding: 0px;
  color: var(--color-5);
  font-size: calc(11px + 2vh + 1vw);
}

h2 {
  display: flex;
  margin: 0px;
  padding: 0px;
  color: var(--color-4);
  font-size: calc(10px + .5vh + .5vw);
  text-align: center;
}

#email {
  color: var(--color-5);
  font-size: calc(10px + .2vh + .2vw);
  padding: 0px;
  margin: 0px;
}

header {
  display: flex;
}

.headerbar {
  display: flex;
}

.header {
padding-bottom: 1vh;
}

.location {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  color: var(--color-6);
}

#locationtag {
  display: flex;
  align-self: center;
  font-size: calc(6px + .5vh + .5vw);
  justify-content: center;
  align-items: center;
}
